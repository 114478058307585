<template>
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
       Corporate Branch Registered Successfully!
    </b-alert>
    </div>
    
<form @submit.prevent="submitForm" method="post">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                
                <div class="row">
                      <div class="col-md-6 mb-3">
                        <label>Select Corporate Type*</label>
                          <multiselect v-model="corporateBranchType" :options="corporateBranchTypeArr" @input="onchangeType();" track-by="typeID" label="typeName"
                        :class="{
                            'is-invalid': submitted && $v.corporateBranchType.$error,
                          }" ></multiselect>

                           <div
                            v-if="submitted && $v.corporateBranchType.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.corporateBranchType.required"
                              >This value is required.</span
                            >
                          </div>
                      </div>
                      <div class="col-md-6 mb-3" v-if="corporateBranchType.typeID==2">
                        <label> Select Principles </label>
                          <multiselect v-model="principleBranchID" :options="principleBranches" track-by="principleBranchID" label="principleBranchName"
                           ></multiselect>
                      </div>

                </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                     <label>Choose Corporate *</label>
                     <multiselect v-model="posts.corporateID" :options="corporates" track-by="corporateID" 
                     label="corporateName"  @input="getEmployees();" 
                     :class="{
                        'is-invalid': submitted && $v.posts.corporateID.$error,
                      }" ></multiselect>
                       <div
                      v-if="submitted && $v.posts.corporateID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.posts.corporateID.required"
                        >This value is required.</span
                      >
                    </div>

                </div>
                     <div class="col-md-6">
                            <label>Location *</label>
                          <multiselect v-model="posts.branchID" :options="branches" track-by="branchID" label="branchName"  :class="{
                        'is-invalid': submitted && $v.posts.branchID.$error,
                      }"></multiselect>
                        <div
                          v-if="submitted && $v.posts.branchID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.branchID.required"
                            >This value is required.</span
                          >
                        </div>

                     </div>
            </div>
            <div class="row">
                  <div class="mb-3 col-md-6">
                    <label>Address   
                      
                      
                        
                    </label>
                      <vue-google-autocomplete
                              ref="address"
                              id="map"
                              classname="form-control"
                              placeholder="Please type your address"
                              v-on:placechanged="getAddressData"
                              country="in"
                              types="establishment"
                              v-model="posts.address"
                              autocomplete="off"
                               :class="{
                                'is-invalid': submitted && $v.posts.addressInput.$error,
                              }"
                            
                          >
                          </vue-google-autocomplete>
                           <div
                          v-if="submitted && $v.posts.addressInput.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.posts.addressInput.required"
                              >This value is required.</span
                            >
                          </div>

                        
                      </div>
                      <div v-if="this.posts.addressInput" class="col-md-4" style="border: 1px solid #d8d8d8;padding: 10px 28px;">
                       
                          <p>{{this.posts.addressInput}}
                              
                               <span style="position: absolute;right: 3px;bottom: -7px;">
                              <button type="button" v-b-modal.modal-standard class="btn" 
                              style="color: #F3766A !important;font-size: 15px;font-weight: 600;">
                                    <i class="uil uil-plus"></i> Edit
                              </button>
                              </span>
                              </p>
                      </div>
                      
                </div>

            <b-modal
                id="modal-standard"
                title="Address"
                title-class="font-18"
                @ok="addNewAddress();"
              >
               <div class="mb-4">
               <label>Address</label>
                  <textarea
                    v-model="posts.addressInput"
                    class="form-control"
                    name="textarea"
                    rows="3"
                  ></textarea>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-pincode-input">Pincode</label>
                          <input type="text" class="form-control" id="formrow-pincode-input" v-model="posts.pincode"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-city-input">City</label>
                          <input type="text" class="form-control" id="formrow-city-input" v-model="posts.city"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-state-input">State</label>
                          <input type="text" class="form-control" id="formrow-state-input" v-model="posts.state"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-country-input">Country</label>
                          <input type="text" class="form-control" id="formrow-country-input" v-model="posts.country"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-latitude-input">Latitude</label>
                          <input type="text" class="form-control" id="formrow-latitude-input" v-model="posts.latitude"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-longitude-input">Longitude</label>
                          <input type="text" class="form-control" id="formrow-longitude-input" v-model="posts.longitude"
                         
                          >
                    </div>
                </div>

              </b-modal>
               <!-- <div class="col-md-12 mb-3">
                     <label>Select Branch Manager </label>
                       <multiselect v-model="posts.corpBranchManID" :options="ownerList" track-by="empID" label="name" ></multiselect>

                </div> -->

   
                <div class="row mb-3">
                        <div class="mb-3 col-6">
                          <label class="form-label" for="formrow-gstNo-input">GST Number</label>
                          <input type="text" class="form-control" id="formrow-gstNO-input" v-model="posts.gstNO">
                      </div>

                    <div class="mb-3 col-6">
                        <label class="form-label" for="formrow-gstName-input">Registered Name as per GST</label>
                        <input type="text" class="form-control" id="formrow-gstName-input" v-model="posts.gstName">
                    </div>
                </div>
                
                <div class="row mb-3">
                    
                      <div class="col-md-6 mb-3">
                             
                          <label class="form-label" for="formrow-noOfEmp-input">No of Employees </label>
                          <input type="number" class="form-control" id="formrow-noOfEmp-input" v-model="posts.noOfEmp">
                    
                      </div>

                </div>

                  <div class =" row" style="float:right;">
                    <b-spinner v-if="loading"
                               class="m-2 col-3"
                               variant="primary"
                               role="status"
                    ></b-spinner>
                    <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                  </div>
            </div>
            </div>
        </div>
      

    </div>

<!--       <div class="col-lg-12">-->
<!--      <div class="card">-->
<!--        <div class="card-body">-->
<!--          <h6> Admin Details </h6>-->
<!--                  <div class="col-md-12 mb-3">-->
<!--                            <label>Select Branch Manager*-->

<!--                            </label>-->
<!--                          <multiselect v-model="posts.branchManagerID" :options="branchManager" :disabled="disabledOwner" track-by="corpAdminID" label="corpAdminName"-->
<!--                          :class="{-->
<!--                        'is-invalid': submitted && $v.posts.branchManagerID.$error,-->
<!--                      }"></multiselect>-->
<!--                       <div-->
<!--                          v-if="submitted && $v.posts.branchManagerID.$error"-->
<!--                          class="invalid-feedback"-->
<!--                        >-->
<!--                          <span v-if="!$v.posts.branchManagerID.required"-->
<!--                            >This value is required.</span-->
<!--                          >-->
<!--                        </div>-->

<!--                        </div>-->
<!--                        <div class="mb-4">-->
<!--                                <input class="form-check-input" type="checkbox" id="autoSizingCheck2" v-model="posts.empType" @change="registerNew();">-->
<!--                                <label class="form-check-label" for="autoSizingCheck2" style="margin-left: 5px;">-->
<!--                                  or Register New-->
<!--                                </label>-->
<!--                            </div>-->

<!--                <div class="row">-->
<!--                    <div class="mb-3 col-md-6">-->
<!--                        <label class="form-label" for="formrow-adminName-input"> Admin Name *</label>-->
<!--                        <input type="text" class="form-control" id="formrow-adminName-input" v-model="posts.adminName" :disabled="disabled"-->
<!--                         :class="{-->
<!--                        'is-invalid': submitted && $v.posts.adminName.$error,-->
<!--                      }"-->
<!--                        >-->
<!--                         <div-->
<!--                          v-if="submitted && $v.posts.adminName.$error"-->
<!--                          class="invalid-feedback"-->
<!--                        >-->
<!--                          <span v-if="!$v.posts.adminName.required"-->
<!--                            >This value is required.</span-->
<!--                          >-->
<!--                        </div>-->


<!--                  </div>-->

<!--                   <div class="mb-3 col-md-6">-->
<!--                        <label class="form-label" for="formrow-branchManPhoneNO-input"> Admin Mobile No *</label>-->
<!--                        <input type="text" class="form-control" id="formrow-branchManPhoneNO-input"-->
<!--                        v-model="posts.adminPhone" :disabled="disabled"-->
<!--                         :class="{-->
<!--                        'is-invalid': submitted && $v.posts.adminPhone.$error,-->
<!--                      }"-->
<!--                        >-->
<!--                         <div-->
<!--                          v-if="submitted && $v.posts.adminPhone.$error"-->
<!--                          class="invalid-feedback"-->
<!--                        >-->
<!--                          <span v-if="!$v.posts.adminPhone.required"-->
<!--                            >This value is required.</span-->
<!--                          >-->
<!--                        </div>-->


<!--                  </div>-->
<!--              </div>-->
<!--               <div class="mb-3 col-md-6">-->
<!--                        <label class="form-label" for="formrow-branchManagerEmail-input">Admin EmailID *</label>-->
<!--                        <input type="text" class="form-control" id="formrow-branchManagerEmail-input"-->
<!--                        v-model="posts.adminEmail" :disabled="disabled"-->
<!--                         :class="{-->
<!--                        'is-invalid': submitted && $v.posts.adminEmail.$error,-->
<!--                      }"-->
<!--                        >-->
<!--                         <div-->
<!--                          v-if="submitted && $v.posts.adminEmail.$error"-->
<!--                          class="invalid-feedback"-->
<!--                        >-->
<!--                          <span v-if="!$v.posts.adminEmail.required"-->
<!--                            >This value is required.</span-->
<!--                          >-->
<!--                        </div>-->

<!--                  </div>-->



<!--                </div>-->
<!--            </div>-->
<!--        </div>-->



      <!-- <button class="btn btn-primary" type="submit" style="position: fixed; bottom: 6px; right: 6px;border-radius: 50%;
    padding: 19px;"><i class="fas fa-check fa-2x"></i></button> -->
</form>
<!-- Success circle button with ripple effect -->

  

</Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import   helperFile   from '../../../helpers/helper';

import {
  required
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Add Corporate Branch",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Switches,
    Multiselect,
    Layout,
    PageHeader,
    VueGoogleAutocomplete,

  },
  data() {
    return {
      title: "Add Corporate Branch",
      items: [
        {
          text: "Corporate Branch",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      branchName: null,
      foodType: [],
      status :'',
      submitted: false,
      address: '',
      disabled: true,
      disabledOwner: false,
      branchManager:[],
      corporates:[],
      branches:[],
      managers:[],
      textarea: "",
      imageData: "",
      tagline:null,
      ownerList:[],
      showDismissibleAlert :false,
      loading: false,
      corporateBranchType:"",
      principleBranches:[],
      principleBranchID:"",
      corporateBranchTypeArr:[
        {"typeID":1,"typeName":"Corporate Branch"},
        {"typeID":2,"typeName":"Sub-Corporate for Principle"},
      ],

      posts:{
        branchID : "",
        corporateID:null,
        branchManagerID :[],
        address:"",
        addressInput:"",
        state:"",
        country:"",
        pincode:"",
        city:"",
        latitude:"",
        longitude:"",
        ownerList:[],
        noOfEmp:"",
        gstName:"",
        gstNO:"",
        corpBranchManID:[],
        // empType:false,
        // adminName:'',
        // adminEmail:'',
        // adminPhone:'',

      }
    };
  },

  validations: {
    posts: {
      corporateID:{
        required,
      },
      addressInput:{
        required,
      },
      branchID:{
        required,
      },
      // branchManagerID:{
      //   required:function() {
      //     return (this.posts.empType==false && this.posts.branchManagerID=="") ? false : true;
      //   },
      // },
      // adminPhone:{
      //   required:function() {
      //     return (this.posts.empType==true && this.posts.adminPhone=="") ? false : true;
      //   },
      //   maxLength: maxLength(10),
      //   minLength: minLength(10),
      // },
      // adminName:{
      //   required:function() {
      //     return (this.posts.empType==true && this.posts.adminName=="") ? false : true;
      //   },
      // },
      // adminEmail:{
      //   required:function() {
      //     return (this.posts.empType==true && this.posts.adminEmail=="") ? false : true;
      //   },
      //   email
      // }
    },
    corporateBranchType:{
      required,
    }
  },
  methods:{
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData) {
      this.address = addressData;
      if(placeResultData){
        this.getAllFields = helperFile.getAddressFieldsFromPlacesApi;
        this.getAllFields(placeResultData);
        this.posts.addressInput = placeResultData.formatted_address;
        this.posts.latitude = placeResultData.geometry.location.lat();
        this.posts.longitude = placeResultData.geometry.location.lng();
      }


    },
    addNewAddress(){
      this.posts.address = this.posts.addressInput;
    },
    onchangeType(){
      if(this.corporateBranchType.typeID==2){
        this.getAllPrincipleBranches();
      }
    },

    submitForm(e){

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        /*
             Add the form data we need to submit
         */

        formData.append('corporateBranchType',this.corporateBranchType.typeID);
        formData.append('addressInput',this.posts.addressInput);
        formData.append('address',this.posts.address);
        formData.append('state',this.posts.state);
        formData.append('city',this.posts.city);
        formData.append('pincode',this.posts.pincode);
        formData.append('country',this.posts.country);
        formData.append('latitude',this.posts.latitude);
        formData.append('longitude',this.posts.longitude);
        formData.append('corporateID',this.posts.corporateID.corporateID);
        formData.append('noOfEmp',this.posts.noOfEmp);
        formData.append('gstNO',this.posts.gstNO);
        formData.append('gstName',this.posts.gstName);
        formData.append('branchID',this.posts.branchID.branchID);
        if(this.principleBranchID){
          formData.append('principleBranchID',this.principleBranchID.principleBranchID);
        }

        e.preventDefault();
        this.axios.post(this.$loggedRole+"/add-corporate-branch",  formData )
            .then((result)=>{
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              setTimeout(() => {
                this.$router.push({name: 'corporateBranchTable'});
              },2000);

            })
            .catch((error)=>{
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    getAllBranchDetails(){
      this.axios.get(this.$loggedRole+"/getAllBranchDetails")
          .then((result)=>{
            this.branches = result.data.data;


          });
    },
    getCorporateList(){
      this.axios.get(this.$loggedRole+"/getCorporateList")
          .then((result)=>{
            this.corporates = result.data.data;

          });
    },
    getEmployees(){
      this.branchManager = [];
      this.posts.branchManagerID = "";

      this.axios.post(this.$loggedRole+"/getEmployeesByCorporateID", {
        corporateID:  this.posts.corporateID.corporateID
      }).then((result)=>{
        this.branchManager = result.data.data;

      });

    },
    getAllPrincipleBranches(){
      this.axios.post(this.$loggedRole+"/getAllPrincipleBranches")
          .then((result)=>{
            this.principleBranches = result.data.data;


          });
    },

    previewImage: function(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },

    registerNew(){
      if(this.disabled){
        this.disabled = false;
        this.disabledOwner = true;
        this.posts.branchManagerID = "";
      }else{
        this.disabled = true;
        this.disabledOwner = false;
        this.posts.adminEmail = "";
        this.posts.adminPhone = "";
        this.posts.adminName = "";
      }


    },


  },

  mounted(){

    this.getAllBranchDetails();
    this.getCorporateList();
    this.$refs.address.focus();

  },
  middleware: "authentication",
};
</script>
<style lang="scss" scoped>

img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
}

.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


